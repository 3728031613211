export default `
  query categoryList {
    categories(filters: { highlighted: { eq: true }}) {
      items {
        uid
        name
        highlighted
        image
        products {items {
          name
          canonical_url
        }}
        url_key
        url_path
        children {
          uid
          name
          highlighted
          image
        }
      }
    }
  }
`;
