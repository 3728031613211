import { Ref, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type{ CategoryTree } from '~/modules/GraphQL/types';
import type {
  UseHighlightedCategoryErrors,
  UseHighlightedCategoryInterface,
} from './useHighlightedCategories';
import categoriesQuery from '~/bold/stores/graphql/highlightedCategories.gql';

/**
 * Make API call to magento to get all categories with highlighted attribute value yes
 */
export function useHighlightedCategories(): UseHighlightedCategoryInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseHighlightedCategoryErrors> = ref({
    load: null,
  });
  const categories: Ref<Array<CategoryTree>> = ref(null);

  const load = async () => {
    Logger.debug('useHighlightedCategory/load');

    try {
      loading.value = true;
      const { data } = await app.context.$vsf.$magento.api.customQuery({ query: categoriesQuery })

      Logger.debug('[categories]:', { data });

      categories.value = data.categories;
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      categories.value = null;
      console.error('getHighlighted', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    load,
    loading,
    error,
    categories,
  };
}

export * from './useHighlightedCategories';

export default useHighlightedCategories;
