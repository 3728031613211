var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full rounded-3xl bg-white relative h-72 lg:h-96 shadow-product"},[_vm._t("image",function(){return [_c('a',{staticClass:"flex justify-center",attrs:{"href":_vm.link}},[(Array.isArray(_vm.image))?_vm._l((_vm.image.slice(0, 2)),function(picture,key){return _c('BoldImage',{key:key,staticClass:"rounded-3xl flex items-center overflow-hidden w-full absolute inset-0",attrs:{"src":picture,"alt":_vm.title,"width":_vm.imageWidth,"height":_vm.imageHeight,"image-tag":_vm.imageTag,"nuxt-img-config":_vm.nuxtImgConfig}})}):_c('BoldImage',{staticClass:"rounded-3xl flex items-center overflow-hidden w-full absolute inset-0",attrs:{"src":_vm.image,"alt":_vm.title,"image-tag":_vm.imageTag,"width":_vm.imageWidth,"height":_vm.imageHeight,"nuxt-img-config":_vm.nuxtImgConfig}}),_vm._v(" "),_vm._t("title",function(){return [_c('div',{staticClass:"category-title-container rounded-br-3xl rounded-bl-3xl bottom-0 absolute w-full flex items-end h-2/6"},[_c('div',{staticClass:"flex gap-2 w-full items-center justify-between p-4"},[_c('span',{staticClass:"text-lg text-white font-bold line-clamp-1 lg:text-xl"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),_c('SvgImage',{attrs:{"icon":"circle-arrow-right","width":"27","height":"27","color":"#FFFFFF"}})],1)])]},null,{ title: _vm.title, link: _vm.link })],2)]},null,{
        image: _vm.image,
        title: _vm.title,
        link: _vm.link,
        imageHeight: _vm.imageHeight,
        imageWidth: _vm.imageWidth,
        imageTag: _vm.imageTag,
        nuxtImgConfig: _vm.nuxtImgConfig,
      })],2)}
var staticRenderFns = []

export { render, staticRenderFns }