//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {ref, useFetch} from '@nuxtjs/composition-api';
import Carousel from '~/bold/components/organisms/Carousel.vue'
import useHighlightedCategories from "../modules/catalog/category/composables/useHighlightedCategories";
import BoldCategoryCard from "./organisms/BoldCategoryCard";

export default {
  name: "HighlightedCategories",
  components: {
    BoldCategoryCard,
    Carousel
  },
  setup() {
    const { categories, load } = useHighlightedCategories();
    const categoryList = ref([]);

    useFetch(async () => {
      await load({pageSize: 10});
      categoryList.value = categories.value
    });

    return {
      categoryList
    }
  },
}
